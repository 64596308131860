/**
 * Based on the following article :
 * https://medium.com/@pppped/compute-an-arbitrary-color-for-user-avatar-starting-from-his-username-with-javascript-cd0675943b66
 */
export const useStringToColor = (
  string_: string,
  options: { saturation?: number; luminance?: number } = {},
): string => {
  const hue =
    [...string_].reduce((_hash, _, index) => {
      _hash = string_.charCodeAt(index) + ((_hash << 5) - _hash);

      return _hash;
    }, 0) % 360;

  const saturation = options.saturation ?? 50;
  const luminance = options.luminance ?? 60;

  return `hsl(${hue}, ${saturation}%, ${luminance}%)`;
};
