<template>
  <v-avatar :color="color" :size="size" :style="styles">
    <app-image
      v-if="src"
      :alt="fullName ?? ''"
      :height="size"
      :src="src"
      :width="size"
    >
      <template #fallback>
        <div class="flex h-full w-full items-center justify-center text-white">
          {{ initials }}
        </div>
      </template>
    </app-image>

    <span v-else class="text-white">{{ initials }}</span>
  </v-avatar>
</template>

<script lang="ts" setup>
const properties = withDefaults(
  defineProps<{
    src?: string | null;
    fullName: string | null;
    size?: number;
  }>(),
  {
    size: 40,
    src: undefined,
  },
);

const initials = computed(() => {
  if (!properties.fullName) return "";

  return properties.fullName
    .toUpperCase()
    .split(" ")
    .map((part) => part.slice(0, 1))
    .slice(0, 2)
    .join("");
});

const color = computed(() => useStringToColor(properties.fullName ?? ""));

const styles = computed(() => {
  return { fontSize: `${properties.size / 2.5}px` };
});
</script>
